<template>
  <div>
    <div class="balance-top">
      <div class="balance-left">
        <div style="display: inline-block">
          <span>{{ $t("账户余额") }}</span>
          <br />
          <span style="font-size: 2rem">฿</span><span style="font-size: 3rem">{{ balance }}</span>
        </div>
      </div>
      <!-- <div>
        <van-button :to="{ name: 'recharge' }" round type="primary">{{
          $t("充值")
        }}</van-button>
        <div class="howtopay" @click="fetchUserInfo">
          {{ $t("如何消费？>>") }}
        </div>
      </div> -->
    </div>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :pulling-text="$t('下拉即可刷新...')"
      :loosing-text="$t('释放即可刷新...')" :loading-text="$t('加载中...')">
      <van-list v-model="loading" :finished="finished" style="margin-top: 10px"
        :style="{ 'min-height': screenHeight + 'px' }" :finished-text="$t('没有更多了')" @load="onLoad">
        <div v-for="item in  datas " :key="item.id" class="item-record van-hairline--bottom">
          <van-cell :title="$t(convertOrderType(item.orderType, item.payChannel))" style="font-size: 16px"
            class="record-top">
            <template>
              <div class="transaction-amount"
                :style="'color:' + (item.orderType != 1 && item.orderType != 13 ? '#bca070' : '')">
                {{ item.orderType == 1 || item.orderType == 13 ? '-' : '+' }} {{ item.totalForeignFee }} {{ $t('铢') }}
              </div>
              <div v-if="item.orderType == 13" class="transaction-arrow">></div>
            </template>
          </van-cell>

          <van-cell :title="formatTime(item.createdAt)" style="color: #999; margin-top: 10px; font-size: 14px">
            <template>
              <div>
                <div v-if="item.sellerBalance != null" class="transaction-balance">
                  {{ $t('余额') + ' ' + item.sellerBalance + ' ' + $t('铢') }}
                </div>
                <div class="transaction-balance" v-if="item.status == 1 || item.status == 5">
                  {{ $t(convertOrderStatus(item.status)) }}
                </div>
              </div>
            </template>
          </van-cell>
        </div>
      </van-list>

      <van-empty v-if="datas.length == 0" :description="$t('暂无记录')" />
    </van-pull-refresh>
  </div>
</template>

<script>
import { Button, List, Toast, PullRefresh, Empty, Cell } from "vant";
import { transactions, fetchUserInfo } from "@/service/api";
import { convertOrderType, convertOrderStatus } from "@/utils/dict";
import { formatShortTime } from "@/utils/utils";

export default {
  components: {
    [List.name]: List,
    [Button.name]: Button,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Empty.name]: Empty,
    [Cell.name]: Cell,
  },
  mounted() {
    this.screenHeight = document.documentElement.clientHeight - 98;
  },
  methods: {
    onRefresh() {
      this.page = 1;
      this.transactions();
    },
    onLoad() {
      this.page++;
      this.transactions();
    },
    convertOrderStatus(status) {
      return convertOrderStatus(status)
    },
    toWebPage(url) {
      window.open(url, "_blank");
    },
    async transactions() {
      const payload = { currentPage: this.page, pageSize: 15, sorter: 'createdAt_desc' };
      const res = await transactions(payload);
      if (res.success) {
        const { data } = res;
        if (this.page === 1) this.datas = [];
        this.datas = this.datas.concat(data);
        this.finished = data.length < 15;
      } else
        this.finished = true
      this.refreshing = false;
      this.loading = false;
    },
    formatTime(item) {
      return formatShortTime(item);
    },
    convertOrderType(orderType, payChannel) {
      return convertOrderType(orderType, payChannel);
    },
    convertPayStatus(val) {
      switch (val) {
        case 1:
          return "待审核";
        case 5:
          return "审核不通过";
        default:
          return "";
      }
    },
    async fetchUserInfo() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const res = await fetchUserInfo();

      if (res && res.success) {
        this.userInfo = res.data;

        const {
          logisticCompany: { phone, address, receiver },
        } = this.userInfo;

        const locale = localStorage.getItem("locale") || "th-TH";
        let hName;
        switch (locale) {
          case "zh-TW":
            hName = "services-cn.html";
            break;
          case "th-TH":
            hName = "services-th.html";
            break;
          default:
            hName = "services-en.html";
            break;
        }

        let webUrl =
          "/services/" +
          hName +
          "?phone=" +
          phone +
          "&address=" +
          encodeURIComponent(address) +
          "&receiver=" +
          receiver;

        // window.open(webUrl, "_blank");
        // EventBus.$emit("openUrl", webUrl);
        window.open(webUrl, "_blank");
      } else Toast.fail(res.message);

      Toast.clear();
    },
  },
  data() {
    return {
      page: 0,
      loading: false,
      finished: false,
      refreshing: false,
      screenHeight: 0,
      datas: [],
      balance: this.$route.query.balance,
    };
  },
};
</script>
<style lang="less" scoped>
.balance-top {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: white;
}

.balance-left {
  flex: 1;
  display: inline-block;
  padding: 0px 20px;
}

.van-button--normal {
  padding: 0px 25px;
}

.van-cell {
  line-height: normal;
  padding: 0px 15px;
}

.van-cell::after {
  border-bottom: 0px;
}

.item-record {
  padding: 10px 0px;
  background-color: white;
}

.record-top {
  .van-cell__value {
    color: #000;
  }

  .van-cell__title {
    min-width: 70%;
  }
}

.howtopay {
  color: #969799;
  font-size: 14px;
  padding-top: 10px;
  text-align: center;
}

.transaction-arrow {
  width: 15px;
  text-align: center;
}
</style>